<template>
<!-- navbar-->
  <section class="main-navbar">
    <div class="container">
        <nav class="navbar navbar-expand-lg  bg-white py-0 fixed-top" id="navbar_id">
          <div class="container">
              <router-link to="/" class="navbar-brand py-0">
                <div class="header-logo">
                  <img src="@/assets/images/quantam_logo.svg" class="img-fluid">
                </div>
              </router-link>
            <button class="navbar-toggler "
                    type="button"
                    toggle="collapse"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    id="hamburger_menu_button"
                    @click="isCollapsed = !isCollapsed"

            >
            <!-- @click="complexClickHandler" -->
              <span class="navbar-toggler-icon" ></span>
            </button>
            <div class="collapse navbar-collapse " :class="{show:!isCollapsed}"
            id="navbarSupportedContent"
            >
            <!-- :class="`collapse navbar-collapse ${visibility}`" -->
              <ul class="navbar-nav ms-auto  mb-0">
                <li @click="handleChange" class="nav-item" v-for="item in pageUrl">
                  <router-link :to="item.tabturl" class="nav-link text-decoration-none">
                    {{item.label}}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div class="overlay" v-if="!isCollapsed" @click="isCollapsed = true"></div>
    </div>
  </section>
<!--  end of navbar-->
</template>
<script>
export default {
  data(){
    return {
      tabturl:'',
      visibility:"",
      isCollapsed: true,
      pageUrl:[
        {
          tabturl:'/',
          label:"Home"
        },
        {
          tabturl:'business',
          label:"Business"
        },
        {
          tabturl:'service',
          label:"Services"
        },
        {
          tabturl:'about',
          label:"About"
        },
        {
          tabturl:'contactUs',
          label:"Contact Us"
        }
      ]
    }
  },
  methods: {
    handleChange(){
      this.visibility=""
      this.isCollapsed=true;
    },
    complexClickHandler(){
      this.visibility="show"
      // var body = document.body
      // body.classList.toggle('open')
    },
    onBodyClick(event) {
      if (!event.target.closest('.navbar-collapse')) {
        this.isCollapsed = true;
      }
    }
  }
}

</script>
<style scoped>

</style>