<template>
<section class="home-page-wrapper ptb-30 mt-20">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xxl-6 col-xl-5 col-lg-7 col-md-5 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="900">
        <div class="big-title">
          <h1 class="f-45 font-bold">Quantum World</h1>
          <h2 class="f-38 font-bold">One stop solutions for your business</h2>
          <div class="description">
            <p class="f-16 font-regular mb-0">We are team of talented developer making Web and Mobile App</p>
          </div>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-7 col-lg-5 col-md-7 col-sm-12 col-12" data-aos="fade-up"
           data-aos-anchor-placement="center-bottom" data-aos-duration="900">
        <div class="business-img">
          <img src="@/assets/images/quantam_busines.svg" class="img-fluid ">
        </div>
      </div>
    </div>
  </div>
</section>
<!--  about us section-->
  <section class="about-wrapper ptb-30">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="middle-title">
            <h2 class="f-40 font-bold text-center text-uppercase">About Us</h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5">
        <div class="col-xxl-6 col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="left-img">
            <img src="@/assets/images/why-us.svg" class="img-fluid" alt="why-quantum">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="900">
            <div class="main-content mob-30">
              <h2 class="f-40 font-bold">Who is <span class="q-color">Quantum World</span> ?</h2>
                <div class="sub-content">
                <p class="f-16">
                  <b>Transforming your business ideas into IT Solutions for sustainable growth.</b></p>
              </div>
          </div>
          <div class="row ">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="quantum-check-list">
                <img class="img-fluid" src="@/assets/images/check.svg" alt="check">
                <span class="f-16 font-medium dark-gray">Managed IT Services</span>
              </div>
              <div class="quantum-check-list">
                <img class="img-fluid" src="@/assets/images/check.svg" alt="check">
                <span class="f-16 font-medium">Proactive Solutions</span>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="quantum-check-list list-mob">
                <img class="img-fluid" src="@/assets/images/check.svg" alt="check">
                <span class="f-16 font-medium">Devoted to Customer Success</span>
              </div>
              <div class="quantum-check-list">
                <img class="img-fluid" src="@/assets/images/check.svg" alt="check">
                <span class="f-16 font-medium">Security that scales not scare</span>
              </div>
            </div>
          </div>
          <div class="col-5">
            <router-link to="/about" class="btn btn-blue">
              Know More
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
<!--  end of about sectiion-->
<!--  service provider-->
  <section class="service-provide-wrapper ptb-30">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="main-content text-center">
            <h2 class="f-40 font-bold">What <span class="q-color">Service</span> we are Providing ?</h2>
            <div class="sub-content">
              <p class="f-16 font-medium text-center">
                <b>Offer your business with the best assistance for growth.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="service-box" data-aos="zoom-in" data-aos-duration="900">
            <div class="s-img">
              <img src="@/assets/images/icons/quantum_enterprice_solutions.svg" class="img-fluid" alt="enterprise">
            </div>
            <div class="serive-title">
              <h3 class="f-20 font-bold">Enterprise Solutions</h3>
              <div class="service-detail">
                <p class="f-16 font-regular dark-gray">
                  The need for a piece of Enterprise software can come from a number of business situations,
                  and a combination of the factors usually feature in the early discussions with our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="service-box" data-aos="zoom-in" data-aos-duration="900">
            <div class="s-img">
              <img src="@/assets/images/icons/quantum_web_solutions.svg" class="img-fluid" alt="enterprise">
            </div>
            <div class="serive-title">
              <h3 class="f-20 font-bold">Web Solutions</h3>
              <div class="service-detail">
                <p class="f-16 font-regular dark-gray">
                  The need for a piece of Enterprise software can come from a number of business situations,
                  and a combination of the factors usually feature in the early discussions with our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="service-box" data-aos="zoom-in" data-aos-duration="900">
            <div class="s-img">
              <img src="@/assets/images/icons/quantum_mobile_solutions.svg" class="img-fluid" alt="enterprise">
            </div>
            <div class="serive-title">
              <h3 class="f-20 font-bold">Mobile Solutions</h3>
              <div class="service-detail">
                <p class="f-16 font-regular dark-gray">
                  The need for a piece of Enterprise software can come from a number of business situations,
                  and a combination of the factors usually feature in the early discussions with our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="service-box" data-aos="zoom-in" data-aos-duration="900">
            <div class="s-img">
              <img src="@/assets/images/icons/quantum_it_solutions.svg" class="img-fluid" alt="enterprise">
            </div>
            <div class="serive-title">
              <h3 class="f-20 font-bold">IT Managed Solutions</h3>
              <div class="service-detail">
                <p class="f-16 font-regular dark-gray">
                  The need for a piece of Enterprise software can come from a number of business situations,
                  and a combination of the factors usually feature in the early discussions with our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!--  end of service provider-->
  <contact-us />
</template>

<script>
import BigTitle from "./mainTitles/bigTitle.vue";
import ContactUs from "./contactUs/contactUs.vue";

export default {
  name: "index",
  components: {ContactUs, BigTitle}
}
</script>

<style scoped>

</style>