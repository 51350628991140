<template>
  <section class="contact-wrapper ptb-30">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="middle-title">
            <h2 class="f-40 font-bold text-center text-uppercase">Apply Here</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="zoom-in" data-aos-duration="900">
          <div class="contact-box">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>103 Capital Goldan Tower, Business Bay, Dubai,UAE</p>
              </div>
              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <a href="mailto:cs@quantumworld.com" class="f-16 text-decoration-none"><p>cs@quantumworld.com</p></a>
              </div>
              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <a href="tel:0521202581" class="f-16 text-decoration-none"><p>+971 5212 02581</p></a>
              </div>
            </div>
            <div class="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.7474697480884!2d55.271648215009165!3d25.17800338390521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6835b37479db%3A0x397214026707f37b!2s57HF%2B5JX%20Capital%20Golden%20Tower%20-%20Business%20Bay%20-%20Dubai!5e0!3m2!1sen!2sae!4v1679657560834!5m2!1sen!2sae"
                      width="470" height="250" style="border:0;"
                      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="zoom-in" data-aos-duration="900">
          <div class="contact-box">
            <form class="contact-form career-form"
                  enctype="multipart/form-data"
                  >
              <div class="row align-items-center">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="fname"
                           v-model="fname"
                           placeholder="First Name"
                           class="form-control"
                           id="fname"
                           required
                    >
                    <label for="fname">First Name</label>
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="lname"
                           v-model="lname"
                           placeholder="Last Name"
                           class="form-control"
                           id="lname"
                           required>
                    <label for="lname">Last Name</label>
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="phone"
                           placeholder="Phone Number"
                           class="form-control"
                           id="phone"
                           required=""
                           v-model="phone"
                    >
                    <label for="phone">Phone Number</label>
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="email"
                           name="email"
                           v-model="email"
                           placeholder="Enter your Email"
                           class="form-control"
                           id="email"
                           required>
                    <label for="email">Email</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="designation"
                           v-model="designation"
                           placeholder="Enter your Designation"
                           class="form-control" id="designation"
                           required="">
                    <label for="designation">Designation</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 ">
                    <label for="my_file">Upload CV</label>
                    <input class="form-control" type="file"  name="my_file" id="my_file">
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 form-floating">
                    <textarea class="form-control"
                              name="msg"
                              v-model="msg"
                              placeholder="Tell us about yourself"
                              rows="6"></textarea>
                    <label for="email">Tell us about yourself</label>
                  </div>
                </div>
                <div class="col-4">
                  <button @click="sendEmail" class="btn btn-blue">Apply</button>
                </div>
                <div class="col-12">
                  <div class="alert alert-danger email-error" role="alert" v-if="errors.email">
                    {{errors.email}}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "careerForm",
  data(){
    return{
      errors: {
        email: null,
      },
      fname:'',
      lname:'',
      phone:'',
      email:'',
      designation:'',
      my_file:'',
      msg:'',
    }
  },
  methods: {
    sendEmail(e) {
      e.preventDefault()
      console.log("sending an email")
      if (!this.email) {
        this.errors.email = 'Email is required';
      } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.errors.email = 'Invalid email';
      }else{
        this.errors.email=null;
        console.log("the error is set to null")
        const document={
          fname: this.fname,
          lname: this.lname,
          phone: this.phone,
          email: this.email,
          designation: this.designation,
          my_file: this.my_file,
          msg: this.msg
        };
        // 'cDFo7NHoZjeZzzIL3',
        emailjs.send(
            'service_erdqvbo',
            'template_kdtedpi',
           document,'cDFo7NHoZjeZzzIL3'
           ) .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
      })
        this.fname = ''
        this.lname = ''
        this.phone =''
        this.email = ''
        this.designation = ''
        this.my_file = ''
        this.msg = ''
      }

    },
  }
}
</script>

<style scoped>

</style>