<template>
  <section class="about-main-wrapper ptb-30 mt-20">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="middle-title">
            <h2 class="f-40 font-bold text-center text-uppercase">About Us</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="about-are-wrapper ptb-30">
    <div class="container">
      <div class="row align-items-center flex-col">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-up"
             data-aos-anchor-placement="center-bottom" data-aos-duration="900">
          <div class="left-img">
            <img src="@/assets/images/why-us.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="main-content"><h2 class="f-40 font-bold">We are <span class="q-color">Quantum World</span></h2><div class="sub-content">
            <p class="f-16">
              <b>Transforming your business ideas into IT Solutions for sustainable growth.</b></p>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  why Quantum World -->
  <section class="why-quantum-wrapper ptb-30">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="main-content text-center">
            <h2 class="f-40 font-bold">Why <span class="q-color">Quantum World</span> ?</h2>
            <div class="sub-content">
              <p class="f-16 text-center">
                <b>Increase your Business by doing best Technologies.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center ptb-30">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
             data-aos="fade-right" data-aos-duration="900">
          <div class="main-content">
            <div class="counting-number">
              <h1>1</h1>
            </div>
            <h2 class="f-40 font-bold">A Highly Trained <br>Technology Staff</h2>
            <div class="sub-content">
              <p class="f-16">
                <b>At a fraction of the cost to hire, train and manage your own internal IT staff.</b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantam_trained_staff.svg" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="row align-items-center ptb-30 flex-col">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantam_services.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="main-content">
            <div class="counting-number">
              <h1>2</h1>
            </div>
            <h2 class="f-40 font-bold">A Passion for <br>Excellent Service</h2>
            <div class="sub-content">
              <p class="f-16">
                <b>At a fraction of the cost to hire, train and manage your own internal IT staff.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center ptb-30">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="main-content">
            <div class="counting-number">
              <h1>3</h1>
            </div>
            <h2 class="f-40 font-bold">Immediate Support</h2>
            <div class="sub-content">
              <p class="f-16">
                <b>Our team includes remote support specialists,and
                  Get answers when you need them..</b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantam_support.svg" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="row align-items-center ptb-30 flex-col">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantm_relaible_service.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="main-content">
            <div class="counting-number">
              <h1>4</h1>
            </div>
            <h2 class="f-40 font-bold">Reliable Service</h2>
            <div class="sub-content">
              <p class="f-16">
                <b>Reliability to Perform service dependably and accurately.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  End of why Quantum World-->
  <contact-us />
</template>

<script>
import ContactUs from "./contactUs/contactUs.vue";

export default {
  name: "about",
  components: {ContactUs}
}
</script>

<style scoped>

</style>