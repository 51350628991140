<template>
  <section class="about-main-wrapper ptb-30 mt-20">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="middle-title">
            <h2 class="f-40 font-bold text-center text-uppercase">Career</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="career-wrapper ptb-30">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="career-box">
            <div class="row">
              <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12">
                <div class="position-title">
                  <h3 class="f-32 font-semibold mb-0">Frontend Developer</h3>
                </div>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 d-flex justify-content-end">
                <router-link to="/careerForm" class="text-decoration-none btn btn-blue">Appply</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="career-box">
            <div class="row">
              <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12">
                <div class="position-title">
                  <h3 class="f-32 font-semibold mb-0">Ui/Ux Designer</h3>
                </div>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 d-flex justify-content-end">
                <router-link to="/careerForm" class="text-decoration-none btn btn-blue">Appply</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="career-box">
            <div class="row">
              <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12">
                <div class="position-title">
                  <h3 class="f-32 font-semibold mb-0">Project Manager</h3>
                </div>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 d-flex justify-content-end">
                <router-link to="/careerForm" class="text-decoration-none btn btn-blue">Appply</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "career",
}
</script>

<style scoped>

</style>