<template xmlns="http://www.w3.org/1999/html">
  <!--  Contact Section-->
  <section class="contact-wrapper ptb-30">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="middle-title">
            <h2 class="f-40 font-bold text-center text-uppercase">Contact</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="zoom-in" data-aos-duration="900">
          <div class="contact-box">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>103 Capital Goldan Tower, Business Bay, Dubai,UAE</p>
              </div>
              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <a href="mailto:cs@quantumworld.com" class="f-16 text-decoration-none"><p>cs@quantumworld.com</p></a>
              </div>
              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <a href="tel:0521202581" class="f-16 text-decoration-none"><p>+971 5212 02581</p></a>
              </div>
            </div>
            <div class="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.7474697480884!2d55.271648215009165!3d25.17800338390521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6835b37479db%3A0x397214026707f37b!2s57HF%2B5JX%20Capital%20Golden%20Tower%20-%20Business%20Bay%20-%20Dubai!5e0!3m2!1sen!2sae!4v1679657560834!5m2!1sen!2sae"
                      width="470" height="250" style="border:0;"
                      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="zoom-in" data-aos-duration="900">
          <div class="contact-box">
            <form class="contact-form"  id="messageForm" >
              <!--              action="https://api.telegram.org/bot6075377295:AAHVxXVy9bbyvwb2yqTgwlyZGxGjv7l03NM"-->
              <div class="row align-items-center">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="fname"
                           placeholder="First Name"
                           class="form-control"
                           id="fname" required=""
                           v-model="details.firstName"
                    >
                    <label for="fname">First Name</label>
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="lname"
                           placeholder="Last Name"
                           class="form-control"
                           id="lname"
                           required=""
                           v-model="details.lastName"
                    >
                    <label for="lname">Last Name</label>
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="phone"
                           placeholder="Phone Number"
                           class="form-control"
                           id="phone"
                           required=""
                           v-model="details.phone"
                    >
                    <label for="phone">Phone Number</label>
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb-3 form-floating">
                    <input type="email"
                           name="email"
                           placeholder="Email"
                           class="form-control"
                           id="email"
                           v-model="details.email"
                           required
                    >
                    <label for="email">Email</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 form-floating">
                    <input type="text"
                           name="subject"
                           placeholder="Enter your Subject"
                           class="form-control" id="subject"
                           required=""
                           v-model="details.subject"
                    >
                    <label for="subject">Subject</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3 form-floating">
                    <textarea class="form-control conct-textarea"
                              name="msg"
                              placeholder="Add your message here . . ."
                              rows="10"
                              id="msg"
                              v-model="details.msg"
                    >
                    </textarea>
                    <label for="subject">Add your message here</label>
                  </div>
                </div>
                <div class="col-4">
                  <button @click="handleSubmit" id="submit-btn" class="btn btn-blue">Submit</button>
                  <div class="alert alert-danger email-error" role="alert" v-if="errors.email">
                    {{errors.email}}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  Contact Section-->
</template>
<script>
import axios from "axios";
export default {
  name: "contactUs",
  data() {
    return{
      details:  {
        firstName:"",
        lastName:"",
        phone:"",
        email:"",
        subject:"",
        msg:"",
        emailAlert:''
      },
      errors: {
        email: null,
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault()
      if (!this.details.email) {
        this.errors.email = 'Email is required';
      } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.details.email)) {
        this.errors.email = 'Invalid email';
      }
      else {
        this.errors.email=null;
        if (this.details.email) {
          let chatKey = "bot5909584326:AAGr4HT0J-X3C5VYksDNmqMF7urXf33x4N4"
          let chatId = "-940137015"
          const questUrl = "https://api.telegram.org/" + chatKey +
              "/sendMessage?parse_mode=Markdown&" +
              "chat_id=" + chatId +
              "&text=" + JSON.stringify(this.details)
          axios.post(questUrl).then((response)=>{
            console.log(response);
            if(response.status=="200"){
              this.details.firstName="";
              this.details.lastName="";
              this.details.phone="";
              this.details.email="";
              this.details.subject="";
              this.details.msg="";
              this.details.emailAlert="Your message has been sent successfully";
            }
          })
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
