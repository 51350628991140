import { createApp } from 'vue'
import './style.css'
import '/src/assets/css/main.scss'
import '/src/assets/css/responsive.css'
import '/src/assets/fonts/raiway_font.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/js/popper.min.js'
import '@/assets/js/bootstrap.min.js'
import App from './App.vue'
import Home from "./components/index.vue"
import about from "./components/about.vue"
import services from "./components/services.vue";
import contactUs from "./components/contactUs/contactUs.vue";
import business from "./components/business.vue";
import career from "./components/career.vue";
import careerForm from "./components/carrerForm/careerForm.vue";
import {createRouter,createWebHashHistory} from "vue-router";

const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path: '/',
            name: "index",
            component: Home,
        },
        {
            path: '/about',
            name: "about",
            component: about
        },
        {
            path: '/service',
            name: "service",
            component: services
        },
        {
            path: '/contactUs',
            name: "contactUs",
            component: contactUs
        },
        {
            path: '/business',
            name: "business",
            component: business
        },
        {
            path: '/career',
            name: "career",
            component: career
        },
        {
            path: '/careerForm',
            name: "careerForm",
            component: careerForm
        }
    ]
})
const app= createApp(App)
app.use(router)
app.mount('#app')



AOS.init(

);
AOS.refresh();

