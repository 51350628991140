<template>
<section class="quantum-business-wrapper ptb-30 mt-20">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="middle-title">
          <h2 class="f-40 font-bold text-center text-uppercase">Business</h2>
        </div>
      </div>
    </div>
  </div>
</section>
  <section class="quantum-vision-mision-wrapper">
    <div class="container">
      <div class="row align-items-center flex-col">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="left-img">
            <img src="@/assets/images/vision.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="main-content"><h2 class="f-40 font-bold">Quantum <span class="q-color">Vision</span></h2>
            <div class="sub-content">
              <p class="f-16">
                By revolutionising the industry and establishing new benchmarks for success and professionalism,
                we aim to be the global leaders in digital services.
                Our ambition is to establish ourselves as a leading firm providing services in design, development,
                and online marketing strategies for the global marketplace.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="main-content">
            <h2 class="f-40 font-bold">Quantum <span class="q-color">Mision</span></h2>
            <div class="sub-content">
              <p class="f-16">
                A shared set of values has been positioned at the centre of how we conduct business.
                Our ideas are not special, but they do represent what we do at our best.
              </p>
              <ul class="list-inline mb-0 mision-point">
                <li><span><i class="bi bi-caret-right-fill"></i></span><span>On time Delivery</span></li>
                <li><span><i class="bi bi-caret-right-fill"></i></span><span>Team Work</span></li>
                <li><span><i class="bi bi-caret-right-fill"></i></span><span>Integrity</span></li>
                <li><span><i class="bi bi-caret-right-fill"></i></span><span>Service above all</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="left-img">
            <img src="@/assets/images/mision.svg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>
  <contact-us />
</template>
<script>
import ContactUs from "./contactUs/contactUs.vue";

export default {
  name: "business",
  components: {ContactUs}
}
</script>

<style scoped>

</style>