<template>
  <div class="big-title">
    <h1>Quantam World</h1>
    <h1>Solutions For Your Business</h1>
  </div>
</template>

<script>
export default {
  name: "bigTitle"
}
</script>

<style scoped>

</style>