<template>
<section class="footer ptb-30 mob-30">
  <div class="container">
    <div class="row align-items-baseline">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
        <div class="foo-logo">
          <img src="@/assets/images/quantam_logo.svg" class="img-fluid" alt="quantam_footer-logo">
          <div class="foo-info">
            <p class="f-16 font-medium">Our support team will get assistance from
              AI-powered suggestions, making it quicker than ever to handle support requests.</p>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12">
        <div class="foo-titles">
          <h3 class="f-20 font-semibold">Userfull Links</h3>
          <ul class="list-inline foo-links mb-0">
            <li>
              <router-link to="/" class="text-decoration-none">Home</router-link>
            </li>
            <li>
              <router-link to="/about" class="text-decoration-none">About Us</router-link>
            </li>
            <li>
              <router-link to="/business" class="text-decoration-none">Business</router-link>
            </li>
            <li>
              <router-link to="/service" class="text-decoration-none">Services</router-link>
            </li>
            <li>
              <router-link to="/career" class="text-decoration-none">Career</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12">
        <div class="foo-titles">
          <h3 class="f-20 font-semibold">Products</h3>
          <ul class="list-inline foo-links mb-0">
            <li>
              <a href="javacript:;" class="text-decoration-none">Crypto Wallet</a>
            </li>
            <li>
              <a href="javacript:;" class="text-decoration-none">AI Service</a>
            </li>
            <li>
              <a href="javacript:;" class="text-decoration-none">Mobile App</a>
            </li>
            <li>
              <a href="javacript:;" class="text-decoration-none">Web App</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12">
        <div class="foo-titles">
          <h3 class="f-20 font-semibold">Keep in Touch</h3>
        </div>
        <div class="foo-info ofc-adres">
          <p class="f-18 font-bold mb-0">Quantam World</p>
          <p class="f-16 font-medium"> Capital Golden Tower - 103 - Marasi <br>Dr - Business Bay - Dubai, UAE</p>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: "FooTer"
}
</script>

<style scoped>

</style>