<template>
  <section class="about-main-wrapper ptb-30 mt-20">
    <div class="container why-quantum-wrapper">
      <div class="row">
        <div class="col-12">
          <div class="middle-title">
            <h2 class="f-40 font-bold text-center text-uppercase">Services</h2>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-12">
          <div class="main-content text-center">
            <h2 class="f-40 font-bold">Quantum World<span class="q-color"> Services</span></h2>
            <div class="sub-content">
              <p class="f-16 text-center">
                <b>Increase your Business by doing best Technologies.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
             data-aos="fade-right" data-aos-duration="900">
          <div class="main-content">
            <h2 class="f-40 font-bold">Web Development</h2>
            <div class="sub-content">
              <p class="f-16">
                A website today acts as a strong reflection of your brand in addition to being
                a source of information about your business online. Furthermore, visitors and buyers
                will steer clear of a website if it is slow or challenging to use. We’re convinced that
                you don’t want your internal workers to suffer as a result of a slow website given this
                headache. Quantum World Technologies uses an effective project management process to produce
                distinctive websites.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantum_web_development.svg" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="row align-items-center  flex-col mob-30">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantum_mobile_development.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="main-content">
            <h2 class="f-40 font-bold">Mobile Development</h2>
            <div class="sub-content">
              <p class="f-16 ">
                Apps have streamlined our work by altering and disrupting entire industries. They provide the
                impression that a complex organisational issue is simple and uncomplicated. But in reality,
                every "Simple App" has a strong technological foundation and well-written code that work
                together to improve the user experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mob-30">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="main-content">
            <h2 class="f-40 font-bold">Software Development</h2>
            <div class="sub-content">
              <p class="f-16">
                Software development’s main objective is to simplify tasks and give accurate data
                monitoring so you can evaluate, organise, and grow your business. Software development
                is the process of conceptualising, defining, designing, programming, testing,
                and bug-fixing that goes into creating and maintaining frameworks or other
                software components
              </p>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantum_software_development.svg" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="row align-items-center  flex-col mob-30">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-right" data-aos-duration="900">
          <div class="Quantum-staff">
            <img src="@/assets/images/quantum_cloud_service.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" data-aos="fade-left" data-aos-duration="900">
          <div class="main-content">
            <h2 class="f-40 font-bold">Cloud Services</h2>
            <div class="sub-content">
              <p class="f-16">
                Our managed Cloud services enable businesses to improve productivity, cut expenses,
                and shorten time-to-market while also minimising overhead. With our on-demand
                enterprise Cloud solutions, equipment, and software, including servers, storage,
                databases, networking, software, and apps, among others, we support customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "services"
}
</script>

<style scoped>

</style>